import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useRef, useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link, useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import { Collapse, Grid, List, ListItemButton, ListItemText, Paper, Skeleton, styled } from '@mui/material';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { removeFromLocalStorage } from '../../../Helper/helper';

const Item = styled(Paper)(({ theme }) => ({
    height: '100%',
    backgroundColor: "#f2f1f1",
    boxShadow: 'none'
}));

function TabNavigation({ categories, premiumAds }) {
    const navigate = useNavigate();
    const menuRef = useRef(null);

    const [drawerMenu, setDrawerMenu] = useState(false);

    // News
    const [newsMenu, setNewsMenu] = useState(false);
    const handleNewsClick = () => {
        setNewsMenu(!newsMenu);
        setProductsMenu(false);
        setTopicsMenu(false);
        setMatrimonyMenu(false);
    };

    // Matrimony
    const [matrimonyMenu, setMatrimonyMenu] = useState(false);
    const handleMatrimonyClick = () => {
        setMatrimonyMenu(!matrimonyMenu);
        setProductsMenu(false);
        setNewsMenu(false);
        setTopicsMenu(false);
    };

    // Products
    const [productsMenu, setProductsMenu] = useState(false);
    const handleProductsClick = () => {
        setProductsMenu(!productsMenu);
        setNewsMenu(false);
        setTopicsMenu(false);
        setMatrimonyMenu(false);
    };

    // Topics
    const [topicsMenu, setTopicsMenu] = useState(false);
    const handleTopicsClick = (event) => {
        setTopicsMenu(!topicsMenu);
        setNewsMenu(false);
        setProductsMenu(false);
        setMatrimonyMenu(false);
    };

    // About
    const handleAboutClick = (event) => {
        navigate(`/about-us`);
        setProductsMenu(false);
        setNewsMenu(false);
        setTopicsMenu(false);
        setMatrimonyMenu(false);
    };

    const handleOpenNavMenu = (event) => {
        setDrawerMenu(true);
    };

    const navigateNewsListOfCategory = (news) => {
        if (news?.categoryName == 'Matrimony') {
            removeFromLocalStorage(window.location.pathname);
            navigate(`/user/matrimony`);
        } else if (news?.categoryName == 'Obituary') {
            removeFromLocalStorage(`/user/obituary`);
            navigate(`/user/obituary`);
        } else {
            removeFromLocalStorage(`/user/news/category/list`);
            navigate(`/user/news/category/list`, {
                state: {
                    category: news?.categoryName,
                    type: news?.type,
                }
            });
        }
        setNewsMenu(false);
        setProductsMenu(false);
        setTopicsMenu(false);
        setMatrimonyMenu(false);
    }

    const navigateMatrimonyWithType = (type) => {
        removeFromLocalStorage(window.location.pathname);
        navigate(`/user/matrimony`, {
            state: {
                matrimonyType: type,
            }
        })
        setNewsMenu(false);
        setProductsMenu(false);
        setTopicsMenu(false);
        setMatrimonyMenu(false);
    }

    // Function to close menus when clicking outside
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setNewsMenu(false);
            setProductsMenu(false);
            setTopicsMenu(false);
            setMatrimonyMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Box ref={menuRef}>
            <Grid container spacing={2} style={{ marginTop: '2px', marginBottom: '10px' }}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Item>
                        <img alt="complex" src={"/UserSideImages/logo1.jpg"} style={{ width: '100%', height: '80px' }} />
                    </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} style={{ paddingTop: '0px' }}>
                    <Item>
                        {premiumAds.length === 0 ? (
                            <Skeleton variant="rounded" animation="wave" style={{ width: "100%", height: "100px" }} />
                        ) : (
                            <div className="slide-container">
                                {premiumAds.length > 1 ? (
                                    <Zoom scale={0.4} defaultIndex={0}>
                                        {premiumAds.map((each, index) => (
                                            <Link to={each?.link} key={index}>
                                                <img
                                                    style={{ width: "100%", height: "100px", borderRadius: 5 }}
                                                    loading="lazy"
                                                    src={each?.image?.url}
                                                    alt={`Premium ${index + 1}`}
                                                />
                                            </Link>
                                        ))}
                                    </Zoom>
                                ) : (
                                    <Link to={premiumAds[0]?.link}>
                                        <img
                                            style={{ width: "100%", height: "100px", borderRadius: 5 }}
                                            loading="lazy"
                                            src={premiumAds[0]?.image?.url}
                                            alt="Premium"
                                        />
                                    </Link>
                                )}
                            </div>
                        )}
                    </Item>
                </Grid>
            </Grid>
            <Box>
                <AppBar position="static" sx={{ backgroundColor: '#1C2365' }}>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <Box sx={{ flexGrow: 1, gap: 2, display: { xs: 'none', md: 'flex' } }}>
                                    <div>
                                        <Button
                                            sx={{ my: 2, color: 'white', fontSize: 16, LineHeight: 24, fontWeight: 400 }}
                                            onClick={() => { removeFromLocalStorage(`/`); navigate(`/`); setNewsMenu(false); setProductsMenu(false); setTopicsMenu(false); }}
                                        >Home
                                        </Button>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Button
                                            sx={{ my: 2, color: 'white', fontSize: 16, LineHeight: 24, fontWeight: 400 }}
                                            endIcon={newsMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            onClick={handleNewsClick}
                                        >News & Views
                                        </Button>
                                        <Collapse in={newsMenu} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {categories && categories.map((cat, index) => (['Kerala', 'National', 'International', 'Obituary', 'Article & Editorial'].includes(cat?.categoryName) &&
                                                    <ListItemButton sx={{ pl: 4 }} key={index} onClick={() => { navigateNewsListOfCategory(cat) }}>
                                                        <ListItemText primary={cat?.categoryName} />
                                                    </ListItemButton>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Button
                                            endIcon={matrimonyMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            sx={{ my: 2, color: 'white', fontSize: 16, LineHeight: 24, fontWeight: 400 }}
                                            onClick={handleMatrimonyClick}
                                        >Matrimony
                                        </Button>
                                        <Collapse in={matrimonyMenu} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => { navigateMatrimonyWithType("all") }}>
                                                    <ListItemText primary={"All"} />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => { navigateMatrimonyWithType("bride") }}>
                                                    <ListItemText primary={"Brides"} />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => { navigateMatrimonyWithType("groom") }}>
                                                    <ListItemText primary={"Grooms"} />
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div>
                                            <Button
                                                sx={{ my: 2, color: 'white', fontSize: 16, LineHeight: 24, fontWeight: 400 }}
                                                endIcon={productsMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                onClick={handleProductsClick}
                                            >Classifieds
                                            </Button>
                                        </div>
                                        <Collapse in={productsMenu} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {categories && categories.map((cat, index) => (['Buy & Sell', 'Rentals', 'Real Estate', 'Vehicles'].includes(cat?.categoryName) &&
                                                    <ListItemButton sx={{ pl: 4 }} key={index} onClick={() => { navigateNewsListOfCategory(cat) }}>
                                                        <ListItemText primary={cat?.categoryName} />
                                                    </ListItemButton>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div>
                                            <Button
                                                sx={{ my: 2, color: 'white', fontSize: 16, LineHeight: 24, fontWeight: 400 }}
                                                endIcon={topicsMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                onClick={handleTopicsClick}
                                            >Topics
                                            </Button>
                                        </div>
                                        <Collapse in={topicsMenu} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {categories && categories.map((cat, index) => (['Kidz', 'Youth', 'Women', 'Success Stories'].includes(cat?.categoryName) &&
                                                    <ListItemButton sx={{ pl: 4 }} key={index} onClick={() => { navigateNewsListOfCategory(cat) }}>
                                                        <ListItemText primary={cat?.categoryName} />
                                                    </ListItemButton>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </div>
                                    <div>
                                        <Button
                                            sx={{ my: 2, color: 'white', fontSize: 16, LineHeight: 24, fontWeight: 400 }}
                                            onClick={handleAboutClick}
                                        >About Us
                                        </Button>
                                    </div>
                                </Box>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </Box>
            <NavBar drawerMenu={drawerMenu} setDrawerMenu={setDrawerMenu} categories={categories} />
        </Box>
    )
}

export default TabNavigation;