import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { FacebookIcon, WhatsappIcon } from 'react-share';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { saveToLocalStorage } from '../../../Helper/helper';

const footer = {
    backgroundColor: 'black',
    color: 'white',
}

const Footer = () => {

    return (
        <Box style={footer}>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12}>
                        <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: 20, paddingBottom: 50, alignItem: 'flex-start' }}>
                            <img alt="complex" src={"/UserSideImages/logo.png"} style={{ width: '50%', height: '40%' }} />
                            <Typography variant="body2">Delivering news and information of churches and missions around the world for Growth, Unity & Edification for the Glory of God.</Typography>

                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12}>
                        <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', gap: 20, paddingBottom: 50, justifyContent: 'center' }}>
                            <Typography variant="body1"><b>Contact Us</b></Typography>
                            <Typography variant="body2">We value your feedback and are here to assist you with any questions or inquiries. Feel free to reach out to us through the following contact details:</Typography>
                            <Typography variant="body2"><b>Address:</b> ONLINEGOODNEWS.COM, Kottayam 686 004, Kerala, India</Typography>
                            <Typography variant="body1"><b>Phone</b></Typography>
                            <Typography variant="body2"><b>India:</b> +91 94473 72726 / +91 99462 05422 / +91 94005 20909</Typography>
                            <Typography variant="body2"><b>USA:</b> +1 (469) 573 0264</Typography>
                            <Typography variant="body2"><b>UK:</b> +44 7951 963062</Typography>
                            <Typography variant="body2"><b>Australia:</b> +61 490043301</Typography>
                            <Typography variant="body2"><b>Gulf:</b> +971 50 354 0676</Typography>
                            <Typography variant="body2"><b>Email:</b> info@onlinegoodnews.com</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12}>
                        <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 20, paddingBottom: 20, justifyContent: 'flex-end' }}>
                            <WhatsappIcon size={32} style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.whatsapp.com', '_blank') }} />
                            <FacebookIcon size={32} style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.facebook.com', '_blank') }} />
                            <YouTubeIcon style={{ background: '#E44B07', height: 32, width: 32, cursor: 'pointer' }} onClick={() => { window.open('https://www.youtube.com', '_blank') }} />
                        </Box>
                    </Grid>
                </Grid>
                <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', gap: 20, paddingBottom: 50, paddingTop: 30, borderTop: '1px solid #2a2b2d' }}>
                    <Typography variant="body2">
                        © {new Date().getFullYear()} All rights reserved.
                    </Typography>
                    <Box style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 20 }}>
                        <Link className='footer-link-style' to="/terms-and-condition" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Terms & Conditions</Link>
                        <Link className='footer-link-style' to="/support" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Support</Link>
                        <Link className='footer-link-style' to="/privacy" onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); }}>Privacy</Link>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
