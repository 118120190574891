import React, { useEffect, useState } from 'react'
import { Box, Button, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getFilteredNewsData } from '../../../Services/UserServices';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { saveToLocalStorage } from '../../../Helper/helper';
import moment from 'moment';

const Item = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    boxShadow: 'none',
    marginBottom: 20
}));

const boxStyle2 = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px",
    gap: "20px"
}

const boxStyleSecondFLex = {
    display: 'flex',
    flexDirection: 'row',
    gap: "10px",
    wrap: 'wrap'
}


function LatestSession({ filteredNews }) {
    const navigate = useNavigate();

    const navigateNewsDetails = (id, slug) => {
        saveToLocalStorage(window.location.pathname, window.scrollY);
        navigate(`/user/news/details/${slug}`);
    }

    const loadNews = (latest) => {
        return (latest.map((news, index) => {
            const renderFilteredContent = (htmlContent) => {
                let arr = [];
                parse(htmlContent, {
                    replace: (domNode) => {
                        arr.push(domNode);
                        return null;
                    },
                });

                const imageNode = arr.find((node) => node?.name === 'img');
                const iframeNode = arr.find((node) => node?.name === 'iframe');

                // Image rendering
                const image = imageNode ? (
                    <img
                        alt="News"
                        src={imageNode?.attribs?.src || "/DefaultImages/news_default_img.jpeg"}
                        style={{ width: '100px', borderRadius: '5px', height: '100px', objectFit: 'cover' }}
                        onClick={() => { navigateNewsDetails(news?._id, news?.slug) }}
                    />
                ) : iframeNode ? (
                    // Iframe rendering
                    <iframe src={iframeNode?.attribs?.src}
                        style={{ width: '100px', borderRadius: '5px', height: '100px' }}
                        title="Video"
                    />
                ) : (
                    // Default image rendering
                    <img
                        alt="Default"
                        src="/DefaultImages/news_default_img.jpeg"
                        style={{ width: '100px', borderRadius: '5px', height: '100px', objectFit: 'cover' }}
                        onClick={() => { navigateNewsDetails(news?._id, news?.slug) }}
                    />
                );

                return { image };
            };

            const { image } = renderFilteredContent(news?.content);
            return (
                <Box key={news?._id}>
                    <Box id={news?._id} style={boxStyleSecondFLex} key={index}>
                        {image}
                        <Box sx={{
                            width: {
                                xs: '100%',    // 100% width on extra small screens
                                sm: '100%',   // 200px width on small screens
                                lg: '230px',   // 230px width on medium screens
                            },
                        }}>
                            <span className="hover-blue-title-text-header" onClick={() => { navigateNewsDetails(news?._id, news?.slug) }}>{news?.title}</span>
                            <span className='latest-session-date-session'>
                                {moment(news?.date).format('DD-MM-YYYY')}
                            </span>
                        </Box>
                    </Box>
                </Box>
            )
        })
        )
    }
    return (
        <Item>
            <Box style={{ marginBottom: "10px" }}>
                <div className='news-details-sub-latest-session-header-style'>LATEST</div>
            </Box>
            <Box style={boxStyle2}>
                {loadNews(filteredNews)}
            </Box>
        </Item>
    )
}

export default LatestSession;