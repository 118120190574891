import React, { useEffect } from 'react'

function Support() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
  }, []);
  return (
    <div style={{ margin: '20px 20px 100% 20px' }}>
      <h2>Support</h2>
    </div>
  )
}

export default Support;