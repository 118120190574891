import React, { useEffect, useState } from 'react'
import { Box, Button, Card, colors, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getFilteredNewsData } from '../../../Services/UserServices';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const Img = styled('img')({
  margin: 'auto 0px auto 0px',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20
}));

function MatrimonialAds({ matrimonialAds }) {

  const boxStyle2 = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px"
  }

  const boxStyleSecondWithBorder = {
    display: 'flex',
    flexDirection: 'row',
    gap: "10px",
    borderTop: '1px solid #e5e5e5',
    marginTop: '15px',
  }

  const navigate = useNavigate();

  const navigateNewsDetails = (id) => {
    navigate(`/user/news/details/${id}`);
  }

  const viewMoreClick = () => {
    navigate(`/user/matrimony`);
  }

  const loadNews = (news) => {
    return (news?.map((matrimony, index) => {
      const renderFilteredContent = (htmlContent) => {
        let arr = [];
        parse(htmlContent, {
          replace: (domNode) => {
            arr.push(domNode);
            return null;
          },
        });

        const imageNode = arr.find((node) => node?.name === 'img');
        const iframeNode = arr.find((node) => node?.name === 'iframe');
        const descriptionNode = arr.find((node) => node?.type === "text");

        // Image rendering
        const image = imageNode ? (
          <img
            alt="News"
            src={imageNode?.attribs?.src || "/DefaultImages/matrimony_default_img.jpeg"}
            style={{ marginTop: '20px', borderRadius: '5px', width: '100px', height: '100px', objectFit: 'cover' }}
            onClick={() => { navigateNewsDetails(matrimony?.slug) }}
          />
        ) : iframeNode ? (
          // Iframe rendering
          <iframe src={iframeNode?.attribs?.src}
            style={{ marginTop: '20px', borderRadius: '5px', width: '100px', height: '100px' }}
            title="Video"
          />
        ) : (
          // Default image rendering
          <img
            alt="Default"
            src="/DefaultImages/matrimony_default_img.jpeg"
            style={{ marginTop: '20px', borderRadius: '5px', width: '100px', height: '100px', objectFit: 'cover' }}
            onClick={() => { navigateNewsDetails(matrimony?.slug) }}
          />
        );

        const description = descriptionNode ? descriptionNode?.data : '';

        return { image, description };
      };

      const { image, description } = renderFilteredContent(matrimony?.content);
      return (
        <Box style={boxStyleSecondWithBorder} key={index}>
          {image}
          <Box>
            <p className="hover-blue-title-text-header" dangerouslySetInnerHTML={{ __html: matrimony?.title }} onClick={() => { navigateNewsDetails(matrimony?.slug) }}>
            </p>
            <p className='description-text-style'>{description}</p>
          </Box>
        </Box>
      )
    })
    )
  }

  return (matrimonialAds.length !== 0 && (
    <Item>
      <Box>
        <div className='news-details-sub-session-header-style'>Matrimonial Ads</div>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={boxStyle2}>
            {loadNews(matrimonialAds)}
          </Box>
        </Grid>
      </Grid>
      <div className='more-news-container-style'>
        <span className='more-from-latest-news-style' onClick={() => { viewMoreClick() }}>
          <span>VIEW MORE</span>
          <DoubleArrowIcon color='error' sx={{ cursor: 'pointer' }} />
        </span>
      </div>
    </Item>
  ))
}

export default MatrimonialAds;