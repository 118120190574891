import { api } from "./api";

export const latestHomeThreeNews = () => {
    return api.get(`/home/latest-3-news`);
};

export const bannerAdds = () => {
    return api.get(`/home/all-banner-ads`);
};

export const premiumAdds = () => {
    return api.get(`/home/latest-premier-ad`);
};

export const getGroupedCategory = () => {
    return api.get(`/home/news-grouped-by-category`);
};

export const getAllBox = () => {
    return api.get(`/home/all-box-ads`);
};


export const getNewsById = (id) => {
    return api.get(`/news/getNewsById/${id}`);
};

export const getNewsBySlugId = (id) => {
    return api.get(`/news/getNewsBySlug/${id}`);
};

export const getFilteredNewsData = (data) => {
    return api.get(`/home/latest-FilteredNews?categoryName=${data?.categoryName}&type=${data?.type}&count=${data?.count}`);
};

export const updateNewsCount = (id) => {
    return api.post(`/news/updateNewsViewCount/${id}`);
};

export const updateNewsCountBySlug = (id) => {
    return api.post(`/news/updateNewsViewCountBySlug/${id}`);
};

export const getFilterMatrimonyNewsData = (data) => {
    return api.get(`home/matrimonyNews?matrimonytype=${data}`);
};