import React from 'react';
import { Box, Card, Grid } from '@mui/material';
import { styled } from '@mui/system';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { saveToLocalStorage } from '../../../Helper/helper';

const Item = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px',
  backgroundColor: '#fff',
  position: 'relative',
  borderRadius: '5px'
}));

function Content({ news }) {
  const navigate = useNavigate();

  const renderFilteredContent = (htmlContent) => {
    let arr = [];

    // Parse the HTML content and collect nodes
    parse(htmlContent, {
      replace: (domNode) => {
        arr.push(domNode);
        return null;
      },
    });

    // Find the first image node
    const imageNode = arr.find((node) => node?.name === 'img');
    // Find the first iframe node
    const iframeNode = arr.find((node) => node?.name === 'iframe');
    // Find the first text node for the description
    const descriptionNode = arr.find((node) => node?.type === "text");

    // Image rendering
    const image = imageNode ? (
      <Box
        component="img"
        alt="Image"
        src={imageNode?.attribs?.src || '/DefaultImages/news_default_img.jpeg'}
        sx={{
          width: '100%',
          height: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: 500,
          },
          objectFit: 'cover',
          borderRadius: '5px'
        }}
        onClick={() => {
          saveToLocalStorage(window.location.pathname, window.scrollY);
          navigate(`/user/news/details/${news?.slug}`)
        }}
      />
    ) : iframeNode ? (
      // Iframe rendering
      <Box
        component="iframe"
        src={iframeNode?.attribs?.src}
        allow="autoplay; encrypted-media"
        allowFullScreen
        sx={{
          width: '100%',
          height: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: 500,
          },
          borderRadius: '5px'
        }}
      />
    ) : (
      // Default image rendering
      <Box
        component="img"
        alt="Default"
        src="/DefaultImages/news_default_img.jpeg"
        sx={{
          width: '100%',
          height: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: 500,
          },
          objectFit: 'cover',
          borderRadius: '5px'
        }}
        onClick={() => {
          saveToLocalStorage(window.location.pathname, window.scrollY);
          navigate(`/user/news/details/${news?.slug}`)
        }}
      />
    );

    // Description rendering
    const description = descriptionNode ? descriptionNode?.data : '';

    return { image, description };
  };

  const { image, description } = renderFilteredContent(news.content);

  return (
    <Item>
      <div>
        {image}
      </div>
      {/* <Card style={{ boxShadow: 'none', backgroundColor: '#F1F1F1', padding: '10px', margin: '20px' }}> */}
      <span className="worldFlex">
        <span className="worldChildFlex">
          <p className="date-time-text-style">{moment(news?.date).format('DD-MM-YYYY')}</p>
        </span>
        <span className="worldChildFlex">
          <VisibilityOutlinedIcon sx={{ height: '20px', width: '20px', color: '#9DC284' }} />
          <p className="date-time-text-style">{news?.viewsCount}</p>
        </span>
      </span>
      {/* </Card> */}
      <div style={{ flex: 1 }}>
        <div className="card-content-for-world-and-news-session">
          <p
            className="hover-blue-title-text-header"
            dangerouslySetInnerHTML={{ __html: news?.title }}
            onClick={() => {
              saveToLocalStorage(window.location.pathname, window.scrollY);
              navigate(`/user/news/details/${news?.slug}`)
            }}
          />
          <p className="description-text-style">{description}</p>
        </div>
      </div>
      <span className="category-card-black">{news?.categoryName}</span>
    </Item>
  );
}

function World({ latestNews }) {
  return (
    <Grid container spacing={2}>
      {latestNews.map((item, index) => index === 0 && (
        <Grid key={index} item xs={12} sm={12} md={12} lg={6}>
          <Content news={item} />
        </Grid>
      ))}
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Swiper
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          navigation={true}
          modules={[Navigation, Autoplay]}
          slidesPerView={1}
          loop={true}
          style={{ width: '100%', height: '100%' }}
        >
          {latestNews.map((item, index) => (index === 1 || index === 2) && (
            <SwiperSlide key={index}>
              <Content news={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Grid>
  );
}

export default World;
